






















































































































import { Component, Watch } from "vue-property-decorator";
import Hint from "@/views/AutomaticPaymentsOverview/Hint.vue";
import Table from "@/components/Table.vue";
import Actions from "@/components/Actions.vue";
import FileCsvButton from "@/components/Button/FileCsvButton.vue";
import PrintButton from "@/components/Button/PrintButton.vue";
import FscCard from "@/components/Card/FscCard.vue";
import FilterAndSearch from "@/components/FilterAndSearch.vue";
import StudentCol from "@/views/AutomaticPaymentsOverview/StudentCol.vue";
import TextDecimal from "@/components/TextDecimal.vue";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";
import VerticalDivider from "@/components/VerticalDivider.vue";
import PaymentHeader from "@/views/AutomaticPaymentsOverview/PaymentHeader.vue";
import BooleanStatus from "@/components/BooleanStatus.vue";
import MailButton from "@/components/Button/MailButton.vue";
import PdfView from "@/views/AutomaticPaymentsOverview/PdfView.vue";
import { mixins } from "vue-class-component";
import PaymentDocumentsMixin from "@/mixins/PaymentDocumentsMixin";
import FileXmarkButton from "@/components/Button/FileXmarkButton.vue";
import CancelInvoiceModal from "@/views/AutomaticPaymentsOverview/CancelInvoiceModal.vue";
import { namespace } from "vuex-class";
import AutomaticPaymentsDocumentsFilter from "@/views/AutomaticPaymentsOverview/AutomaticPaymentsDocumentsFilter.vue";
import { downloadFile } from "@/utils/File";
import RemoveButton from "@/components/Button/RemoveButton.vue";
import FscModal from "@/components/Modal/FscModal.vue";
import { AxiosError, AxiosResponse } from "axios";
import axios from "@/utils/axios";
import AbortButton from "@/components/Button/AbortButton.vue";
import PaymentDocumentsEmailForm from "@/views/AutomaticPaymentsOverview/PaymentDocumentsEmailForm.vue";
import UserService from "@/services/UserService";

const PaymentDocumentModule = namespace("payment-document");
const PaymentDocumentLocalFilterModule = namespace("payment-document/localFilter");
const LicenseClassModule = namespace("license-class");
const PaymentWorkflowModule = namespace("payment-workflow");
const EmailsModule = namespace("emails");

@Component({
  components: {
    CancelInvoiceModal,
    FileXmarkButton,
    PdfView,
    MailButton,
    BooleanStatus,
    PaymentHeader,
    VerticalDivider,
    FscSimpleCard,
    TextDecimal,
    StudentCol,
    FilterAndSearch,
    FscCard,
    PrintButton,
    FileCsvButton,
    Actions,
    Table,
    Hint,
    AutomaticPaymentsDocumentsFilter,
    RemoveButton,
    FscModal,
    AbortButton,
    PaymentDocumentsEmailForm,
  },
})
export default class PaymentDocuments extends mixins(PaymentDocumentsMixin) {
  public name = "PaymentDocuments";

  @PaymentDocumentLocalFilterModule.State("filter")
  public paymentDocumentsLocalFilter: any;

  @PaymentDocumentLocalFilterModule.Mutation("SET_LOCAL_FILTER")
  public setPaymentDocumentsLocalFilter!: (payload: Record<any, any>) => void;

  @PaymentDocumentModule.Action("cancelInvoice")
  protected cancelInvoice!: (invoiceForCancellationId: number) => Promise<void>;

  @PaymentDocumentModule.Getter("getCancelInvoiceSuccess")
  protected getCancelInvoiceSuccess!: boolean;

  @PaymentDocumentModule.Action("fetchPaymentDocumentsFiltered")
  public automaticPaymentDocumentFilter: any;

  @PaymentDocumentModule.Getter("getDataList")
  public automaticPaymentDocumentsFiltered: any;

  @PaymentDocumentModule.Getter("getTotal")
  public totalRows: any;

  @LicenseClassModule.Action("findAll")
  public findAllLicenseClasses: any;

  @LicenseClassModule.Getter("getDataList")
  public allLicenseClasses: any;

  @PaymentWorkflowModule.Action("findAll")
  public findAllPaymentWorkflows: any;

  @PaymentWorkflowModule.Getter("getDataList")
  public allPaymentWorkflows: any;

  @EmailsModule.Action("setBlobFile")
  private setBlobFile: any;

  @EmailsModule.Getter("getSuccess")
  public emailSuccess: any;

  @EmailsModule.Getter("getError")
  public emailError: any;

  protected tableFields = [
    {
      key: "selected",
      label: "",
      class: "w-30",
    },
    {
      key: "date",
      label: this.$t("calendar.form_date"),
    },
    {
      key: "customerName",
      label: this.$t("general.customer"),
    },
    {
      key: "licenseClass",
      label: this.$t("calendar.class"),
    },
    {
      key: "paymentDocumentNumber",
      label: this.$t("general.number"),
    },
    {
      key: "paymentDocumentTypeName",
      label: this.$t("general.type"),
    },
    {
      key: "paymentWorkflowName",
      label: this.$t("automatic_payment.payment_workflow_name"),
    },
    {
      key: "amountInEur",
      label: this.$t("students.amount"),
    },
    // TODO: add field
    {
      key: "studentDocumentId",
      label: "",
    },
  ];

  public filterActiveIcon = false;
  protected filter: any = { costBearerId: null };
  protected deletePaymentNoticeModalId = "delete-payment-notice-modal-id";
  protected deletePaymentNoticeMessage = "";
  protected deletePaymentNoticeIsAllowed = false;
  public visibleEmailForm = false;
  public currentQuery: any = null;

  public user = this.currentUser || null;

  public mounted(): void {
    if (this.paymentDocumentsLocalFilter instanceof Object) {
      this.filter = this.paymentDocumentsLocalFilter;
      this.filterActiveIcon = true;
    }
  }

  protected async okCancelInvoice(): Promise<void> {
    if (!this.selectedIsCancelable) return;
    await this.cancelInvoice(this.selectedPaymentDocuments[0].paymentDocumentId);

    if (this.getCancelInvoiceSuccess) {
      this.requestQuery();
    }
  }

  public async onFilterModalPopup(): Promise<any> {
    await this.findAllLicenseClasses({ resource: "license-classes" });
    await this.findAllPaymentWorkflows({ resource: "payment-workflows" });
    this.$bvModal.show("automaticPaymentsFilterPopover");
  }

  private resetCurrentQuery() {
    this.currentQuery.limit = 25;
    this.currentQuery.offset = 0;
    this.currentQuery.searchTerm = "";
  }

  public async onAutomaticPaymentsFilter(data: { filter: any }): Promise<void> {
    this.filter = data.filter;
    this.resetCurrentQuery();
    this.setPaymentDocumentsLocalFilter(data.filter);
    this.requestQuery();
    this.filterActiveIcon = true;
    this.$bvModal.hide("automaticPaymentsFilterPopover");
  }

  public async onAbortAutomaticPaymentsFilter(data: { filter: any }): Promise<void> {
    this.filter = data.filter;
    this.resetCurrentQuery();
    this.setPaymentDocumentsLocalFilter(data.filter);
    this.requestQuery();
    this.filterActiveIcon = false;
  }

  protected onCsvExport(): void {
    downloadFile({
      method: "post",
      url: "/payment-documents/filter/csv",
      data: this.filter,
    });
  }

  public onRemovePaymentDocument() {
    this.deletePaymentNoticeMessage = "";
    this.deletePaymentNoticeIsAllowed = false;
    if (this.selectedPaymentDocuments.length > 1) {
      this.deletePaymentNoticeMessage = this.$tc("automatic_payment.more_than_one_payment");
      this.deletePaymentNoticeIsAllowed = false;
    } else {
      if (this.selectedRow && this.selectedRow.paymentDocumentType === "PAYMENT_REQUEST") {
        this.deletePaymentNoticeMessage = this.$tc("automatic_payment.payment_delete");
        this.deletePaymentNoticeIsAllowed = true;
      } else {
        this.deletePaymentNoticeMessage = this.$tc("automatic_payment.only_payment");
        this.deletePaymentNoticeIsAllowed = false;
      }
    }
    this.$bvModal.show(this.deletePaymentNoticeModalId);
  }

  protected cancelDeletePaymentNotice() {
    this.deletePaymentNoticeMessage = "";
    this.$bvModal.hide(this.deletePaymentNoticeModalId);
  }

  protected async triggerDeletePaymentNotice() {
    this.$bvModal.hide(this.deletePaymentNoticeModalId);
    await this.deleteCall();
    await this.requestQuery();
  }

  protected deleteCall() {
    const id = this.selectedRow ? this.selectedRow.paymentDocumentId : null;
    const url = "payment-requests/";
    return axios
      .delete(url + id)
      .then(({ data }: AxiosResponse) => {
        return data;
      })
      .catch((err: AxiosError) => {
        const message = typeof err.response !== "undefined" ? err.response.data.message : err.message;
        return message;
      });
  }

  public onPaymentDocumentEmail(): void {
    const options = {
      blob: this.paymentDocBlob ? this.paymentDocBlob : null,
      mimeType: this.paymentDocMimeType ? this.paymentDocMimeType : null,
    };
    this.setBlobFile(options);
    this.visibleEmailForm = true;
  }

  public onPaymentDocumentsPreviewClose() {
    this.paymentDocSource = "";
  }

  public onEmailAbort(): void {
    this.visibleEmailForm = false;
    this.paymentDocSource = "";
  }

  public get currentUser(): any {
    return UserService.getUser();
  }

  public requestQuery(args?: any) {
    if (args) {
      this.currentQuery = args;
    }
    const options = {
      resource: "payment-documents/filter",
      filter: { ...this.filter, ...this.currentQuery },
    };
    this.automaticPaymentDocumentFilter(options);
  }

  public openPdfForm(row: any) {
    this.visibleEmailForm = false;
    this.onDocRowClicked(row);
  }

  public get receiverNameAndEmail() {
    let receiver: any;
    receiver = {
      // in cost bearer we have cb (student name) so we remove all with bracets
      name: this.selectedRow.customerName.replace(/\s*\(.*?\)/g, ''),
      email: this.selectedRow.emailAddress,
    };
    return receiver;
  }

  @Watch("emailSuccess")
  public onEmailSuccess(success: any) {
    if (success) {
      this.visibleEmailForm = false;
      this.$toasted.success(this.$t("messages.email_sent").toString());
    }
  }

  @Watch("emailError")
  public onEmailError(error: any) {
    if (error) {
      this.$toasted.error(this.$t("messages.email_not_sent").toString());
    }
  }
}
